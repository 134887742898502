import React from 'react'
import BaseComponent from './BaseComponent'
import { List, arrayMove } from "react-movable";
import _ from 'lodash'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import SingleImageUpload from './SingleImageUpload'
import SingleFileUpload from './SingleFileUpload'
import AddTerm from './AddTerm'
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from "react-datepicker";
import pl from 'date-fns/locale/pl';
import MultiselectComponent from './Multiselect';
import Papa from 'papaparse'
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';

registerLocale('pl', pl)

const RemovableIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#555"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-x-circle"
  >
    <title>Delete</title>
    <circle cx="12" cy="12" r="10" />
    <line x1="15" y1="9" x2="9" y2="15" />
    <line x1="9" y1="9" x2="15" y2="15" />
  </svg>
);

const HandleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#555"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-move"
  >
    <polyline points="5 9 2 12 5 15" />
    <polyline points="9 5 12 2 15 5" />
    <polyline points="15 19 12 22 9 19" />
    <polyline points="19 9 22 12 19 15" />
    <line x1="2" y1="12" x2="22" y2="12" />
    <line x1="12" y1="2" x2="12" y2="22" />
  </svg>
);

const buttonStyles = {
  position: 'absolute',
  top: '20px',
  right: '20px',
  border: 'none',
  margin: 0,
  padding: 0,
  width: 'auto',
  overflow: 'visible',
  cursor: 'pointer',
  background: 'transparent'
};

export default class Modules extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { files: [], CSVFile: null, parsedCSV: null }
    this._bind('updateItem', 'onFileChange', 'removeItem', 'clearItem', 'handleCSVFileChange', 'parseCSVFile')
  }

  updateItem(index, type, value) {
    let items = [...this.props.items]

    if(index || index === 0) { 
      items[index][type] = value
      this.props.changeModuleItems(items)
    }
  }

  removeItem(index) {
    let items = [...this.props.items]
    items.splice(index, 1)
    this.props.changeModuleItems(items)
  }

  clearItem(index, value) {
    let items = [...this.props.items]
    items[index] = {type: value, custom: ""}
    this.props.changeModuleItems(items)
  }

  onFileChange(file, name, index) {
    let files = this.state.files
    if (_.filter(files, { name: name }).length < 1) {
      files.push(file)
    }
    this.setState({ files: files })
    this.updateItem(index, "fileName", [name])
    this.props.onFilesChange(files)
    //console.log(file, name)
  }

  handleCSVFileChange(e) {
    if (e.target.files) {
      this.setState({CSVFile: e.target.files[0]})
    }
  }

  parseCSVFile(metadata, key, fileName) {
    let questions = {}
    let _self = this

    Papa.parse(this.state.CSVFile, {
      header: true,
      dynamicTyping: true,
      complete: function(results) {
        let filteredResults = results.data.filter((item) => {
          return item.questionType
        })
        filteredResults.map((item, i) => {
          const filteredAnswers = results.data.filter((answer) => {
            return answer.questionIdent === item.questionIdent
          })

          const answers = filteredAnswers.map((answer, i) => {
            return {
              ident: answer.answerIdent,
              answer: answer.answerLabel
            }
          })

          let adjacentTo = []

          for (let i = 0; i < filteredAnswers.length; i++) {
            const element = filteredAnswers[i];
            if(element.nextQuestion) adjacentTo.push({
              value: element.answerIdent,
              node: element.nextQuestion
            })
          }

          questions[item.questionIdent] = {
            ident: item.questionIdent,
            question: item.questionCopy,
            adjacentTo: adjacentTo.length > 0 ? adjacentTo : null,
            type: item.questionType,
            image: item.questionImg,
            multiselectLimit: item.multiselectLimit,
            answers: answers
          }
          return null
        })
        metadata.questions = questions
        metadata.fileName = fileName
        _self.updateItem(key, "metadata", metadata)
      }
    });
  }

  render () {
    //console.log(this.state)
    return (
      <div className="modules">
        <List
          values={this.props.items || []}
          onChange={({ oldIndex, newIndex }) =>
            this.props.changeModuleItems(arrayMove(this.props.items, oldIndex, newIndex))
          }
          renderList={({ children, props }) => <ul {...props}>{children}</ul> }
          renderItem={({ value, props, isDragged, isSelected }) => { 
            let component = null
            if(value.type === "expiration_date") {
              let val = value

              component = <div>
                <h4>Expiration date</h4>
                <input type="text" value={ val.custom } onChange={ (e) => { this.updateItem(props.key, "custom", e.target.value) } }/>
              </div>
            } else if (value.type === "poll") {
              let val = value

              if(!val.metadata) {
                val.metadata = {
                  questions: {},
                  fileName: ""
                }
              }

              component = <div>
                <h4>Poll CSV importer</h4>
                <input type="file" onChange={this.handleCSVFileChange} />
                { this.state.CSVFile && <button className='btn' onClick={(e) => { e.preventDefault(); this.parseCSVFile(val.metadata, props.key, this.state.CSVFile.name) }}><span>Parse CSV</span></button> }
                { val.metadata?.fileName && <h4>File preview</h4> }
                { val.metadata?.fileName && <input type="text" disabled value={val.metadata?.fileName}/> }
                { val.metadata?.questions && <JSONPretty id="json-pretty" style={{maxHeight: "300px", overflow: "auto"}} data={val.metadata?.questions}></JSONPretty> }
                {/* <input type="text" value={ val.custom } onChange={ (e) => { this.updateItem(props.key, "custom", e.target.value) } }/> */}
              </div>
            } else if (value.type === "text" || value.type === "intro" || value.type === "disclaimer" || value.type === "how_to_use_activity") {
              let val = value

              if(!val.metadata) {
                val.metadata = {
                  highlighted: ""
                }
              }

              component = <div>
                  <h4>HTML</h4>
                  <CKEditor
                    editor={ ClassicEditor }
                    config={ {
                      toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                      heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                    } }
                    data={ value?.custom || "" }
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        // console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        //console.log( { event, editor, data } );
                        this.updateItem(props.key, "custom", data)
                    } }
                    onBlur={ ( event, editor ) => {
                        //console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        //console.log( 'Focus.', editor );
                    } }
                  />
                  <div>
                    <h4>Highlight</h4>
                    <select value={ val.metadata.highlighted } onChange={ (e) => { val.metadata.highlighted = e.target.value; this.updateItem(props.key, "metadata", val.metadata); } }>
                      <option value="">None</option>
                      <option value="border">Border</option>
                      <option value="background">Background color</option>
                    </select>
                  </div>
                </div>
            } else if (value.type === "faq") {
              let val = value

              if(!val.metadata) {
                val.metadata = {
                  title: ""
                }
              }

              console.log(val)

              component = <div>
                  <div>
                    <h4>Title</h4>
                    <input type="text" value={ val.metadata.title } onChange={ (e) => { val.metadata.title = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  </div>
                  <h4>HTML</h4>
                  <CKEditor
                    editor={ ClassicEditor }
                    config={ {
                      toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                      heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                    } }
                    data={ value?.custom || "" }
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        // console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        //console.log( { event, editor, data } );
                        this.updateItem(props.key, "custom", data)
                    } }
                    onBlur={ ( event, editor ) => {
                        //console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        //console.log( 'Focus.', editor );
                    } }
                  />
                </div>
            } else if (value.type === "description_fail" || value.type === "description_success") {
              let val = value

              if(!val.metadata) {
                val.metadata = {
                  title: ""
                }
              }

              component = <div>
                { value.type === "description_success" ? <h4>Description - success</h4> : <h4>Description - fail</h4> }
                <p>Title</p>
                <input type="text" value={ val.metadata.title } onChange={ (e) => { val.metadata.title = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                <p>Description</p>
                <CKEditor
                    editor={ ClassicEditor }
                    config={ {
                      toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                      heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                    } }
                    data={ value?.custom || "" }
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        // console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        //console.log( { event, editor, data } );
                        this.updateItem(props.key, "custom", data)
                    } }
                    onBlur={ ( event, editor ) => {
                        //console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        //console.log( 'Focus.', editor );
                    } }
                />
              </div>
            } else if(value.type === "columns") {
              let val = value
              if (!val.columns) {
                val.columns = [{custom: ""}, {custom: ""}]
              }

              if(!val.metadata) {
                val.metadata = {
                  forceMobile: false,
                  highlighted: ""
                }
              }

              component = (
                <div>
                  <h4>Columns</h4>
                  <div className="flex-columns">
                    <CKEditor
                      editor={ ClassicEditor }
                      config={ {
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                        heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                      } }
                      data={ val.columns[0].custom }
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                          const data = editor.getData();
                          val.columns[0].custom = data
                          // console.log( { event, editor, data } );
                          this.updateItem(props.key, "columns", val.columns)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                    />
                    <CKEditor
                      editor={ ClassicEditor }
                      config={ {
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                        heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                      } }
                      data={ val.columns[1].custom }
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        val.columns[1].custom = data
                        // console.log( { event, editor, data } );
                        this.updateItem(props.key, "columns", val.columns)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                    />
                  </div>
                  <div>
                    <h4>Highlight</h4>
                    <select value={ val.metadata.highlighted } onChange={ (e) => { val.metadata.highlighted = e.target.value; this.updateItem(props.key, "metadata", val.metadata); } }>
                      <option value="">None</option>
                      <option value="border">Border</option>
                      <option value="background">Background color</option>
                    </select>
                  </div>
                  <div className='checkbox'>
                    <input id={'input-force-mobile-'+props.key} type="checkbox" value={ val.metadata.forceMobile } checked={ val.metadata.forceMobile } onChange={ (e) => { val.metadata.forceMobile = !val.metadata.forceMobile; this.updateItem(props.key, "metadata", val.metadata); } }/>
                    <label htmlFor={'input-force-mobile-'+props.key}>Force columns on mobile</label>
                  </div>
                </div>
              )
            } else if(value.type === "columns-3") {
              let val = value
              if (!val.columns) {
                val.columns = [{custom: ""}, {custom: ""}, {custom: ""}]
              }

              if(!val.metadata) {
                val.metadata = {
                  forceMobile: false,
                  highlighted: ""
                }
              }

              component = (
                <div>
                  <h4>Columns</h4>
                  <div className="flex-columns three">
                    <CKEditor
                      editor={ ClassicEditor }
                      config={ {
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                        heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                      } }
                      data={ val.columns[0].custom }
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                          const data = editor.getData();
                          val.columns[0].custom = data
                          // console.log( { event, editor, data } );
                          this.updateItem(props.key, "columns", val.columns)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                    />
                    <CKEditor
                      editor={ ClassicEditor }
                      config={ {
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                        heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                      } }
                      data={ val.columns[1].custom }
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        val.columns[1].custom = data
                        // console.log( { event, editor, data } );
                        this.updateItem(props.key, "columns", val.columns)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                    />
                    <CKEditor
                      editor={ ClassicEditor }
                      config={ {
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                        heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                      } }
                      data={ val.columns[2].custom }
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        val.columns[2].custom = data
                        // console.log( { event, editor, data } );
                        this.updateItem(props.key, "columns", val.columns)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                    />
                  </div>
                  <div>
                    <h4>Highlight</h4>
                    <select value={ val.metadata.highlighted } onChange={ (e) => { val.metadata.highlighted = e.target.value; this.updateItem(props.key, "metadata", val.metadata); } }>
                      <option value="">None</option>
                      <option value="border">Border</option>
                      <option value="background">Background color</option>
                    </select>
                  </div>
                  <div className='checkbox'>
                    <input id={'input-force-mobile-'+props.key} type="checkbox" value={ val.metadata.forceMobile } checked={ val.metadata.forceMobile } onChange={ (e) => { val.metadata.forceMobile = !val.metadata.forceMobile; this.updateItem(props.key, "metadata", val.metadata); } }/>
                    <label htmlFor={'input-force-mobile-'+props.key}>Force columns on mobile</label>
                  </div>
                </div>
              )
            } else if (value.type === "image-text") {
              let val = value

              if (!val.file) {
                val.file = {uuid: ""}
              }

              if(!val.metadata) {
                val.metadata = {
                  layout: "left",
                  alt: "",
                  imgDesc: "",
                  highlighted: false
                }
              }

              component = <div>
                  <h4>Layout</h4>
                  <select onChange={ (e) => { val.metadata.layout = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } } value={ val.metadata.layout }>
                      <option value="left">Text on left</option>
                      <option value="right">Text on right</option>
                  </select>
                  <h4>Content (HTML)</h4>
                  <CKEditor
                      editor={ ClassicEditor }
                      config={ {
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                        heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                      } }
                      data={ val.custom }
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        //console.log( { event, editor, data } );
                        this.updateItem(props.key, "custom", data)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                  />
                  <h4>Image</h4>
                  <SingleImageUpload ident={ "image" } API={ this.props.API } image={ val.file } onChange={ (img) => {
                    val.file = img
                    this.updateItem(props.key, "file", val.file)
                    } }/>
                  <p className="input-desc">Image description</p>
                  <input type="text" value={ val.metadata.imgDesc } onChange={ (e) => { val.metadata.imgDesc = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <div className='checkbox'>
                    <input id={'input-highlighted-'+props.key} type="checkbox" value={ val.metadata.highlighted } checked={ val.metadata.highlighted } onChange={ (e) => { val.metadata.highlighted = !val.metadata.highlighted; this.updateItem(props.key, "metadata", val.metadata); } }/>
                    <label htmlFor={'input-highlighted-'+props.key}>Highlighted</label>
                  </div>
                </div>
            } else if (value.type === "award") {
              let val = value

              if (!val.file) {
                val.file = {uuid: ""}
              }

              if(!val.metadata) {
                val.metadata = {
                  layout: "left",
                  alt: "",
                  imgDesc: "",
                  highlighted: false,
                  buttonCopy: "",
                  buttonUrl: ""
                }
              }

              component = <div>
                  <h4>Layout</h4>
                  <select onChange={ (e) => { val.metadata.layout = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } } value={ val.metadata.layout }>
                      <option value="left">Text on left</option>
                      <option value="right">Text on right</option>
                  </select>
                  <h4>Content (HTML)</h4>
                  <CKEditor
                      editor={ ClassicEditor }
                      config={ {
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                        heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                      } }
                      data={ val.custom }
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        //console.log( { event, editor, data } );
                        this.updateItem(props.key, "custom", data)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                  />
                  <h4>Image</h4>
                  <SingleImageUpload ident={ "image" } API={ this.props.API } image={ val.file } onChange={ (img) => {
                    val.file = img
                    this.updateItem(props.key, "file", val.file)
                    } }/>
                  <p className="input-desc">Image description</p>
                  <input type="text" value={ val.metadata.imgDesc } onChange={ (e) => { val.metadata.imgDesc = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <h4>Button</h4>
                  <p className="input-desc">Button text</p>
                  <input type="text" value={ val.metadata.buttonCopy } onChange={ (e) => { val.metadata.buttonCopy = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <p className="input-desc">URL</p>
                  <input type="text" value={ val.metadata.buttonUrl } onChange={ (e) => { val.metadata.buttonUrl = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <div className='checkbox'>
                    <input id={'input-highlighted-'+props.key} type="checkbox" value={ val.metadata.highlighted } checked={ val.metadata.highlighted } onChange={ (e) => { val.metadata.highlighted = !val.metadata.highlighted; this.updateItem(props.key, "metadata", val.metadata); } }/>
                    <label htmlFor={'input-highlighted-'+props.key}>Highlighted</label>
                  </div>
                </div>
            } else if (value.type === "timer-image-text") {
              let val = value

              if (!val.file) {
                val.file = {uuid: ""}
              }

              if(!val.metadata) {
                val.metadata = {
                  layout: "left",
                  alt: "",
                  imgDesc: "",
                  dateFrom: new Date().toISOString(),
                  dateTo: new Date().toISOString()
                }
              }

              component = <div>
                  <h4>Layout</h4>
                  <select onChange={ (e) => { val.metadata.layout = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } } value={ val.metadata.layout }>
                      <option value="left">Text on left</option>
                      <option value="right">Text on right</option>
                  </select>
                  <h4>Content (HTML)</h4>
                  <CKEditor
                      editor={ ClassicEditor }
                      config={ {
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                        heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                      } }
                      data={ val.custom }
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        //console.log( { event, editor, data } );
                        this.updateItem(props.key, "custom", data)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                  />
                  <h4>Image</h4>
                  <SingleImageUpload ident={ "image" } API={ this.props.API } image={ val.file } onChange={ (img) => {
                    val.file = img
                    this.updateItem(props.key, "file", val.file)
                    } }/>
                  <p className="input-desc">Image description</p>
                  <input type="text" value={ val.metadata.imgDesc } onChange={ (e) => { val.metadata.imgDesc = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <p className="input-desc">Data od:</p>
                  <input type="datetime-local" value={ val.metadata.dateFrom } onChange={ (e) => { val.metadata.dateFrom = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <p className="input-desc">Data do:</p>
                  <input type="datetime-local" value={ val.metadata.dateTo } onChange={ (e) => { val.metadata.dateTo = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                </div>
            } else if (value.type === "cyclic-timer-image-text") {
              let val = value

              if (!val.file) {
                val.file = {uuid: ""}
              }

              if(!val.metadata) {
                val.metadata = {
                  layout: "left",
                  alt: "",
                  imgDesc: ""
                }
              }

              component = <div>
                  <h4>Layout</h4>
                  <select onChange={ (e) => { val.metadata.layout = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } } value={ val.metadata.layout }>
                      <option value="left">Text on left</option>
                      <option value="right">Text on right</option>
                  </select>
                  <h4>Content (HTML)</h4>
                  <CKEditor
                      editor={ ClassicEditor }
                      config={ {
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                        heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                      } }
                      data={ val.custom }
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        //console.log( { event, editor, data } );
                        this.updateItem(props.key, "custom", data)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                  />
                  <h4>Image</h4>
                  <SingleImageUpload ident={ "image" } API={ this.props.API } image={ val.file } onChange={ (img) => {
                    val.file = img
                    this.updateItem(props.key, "file", val.file)
                    } }/>
                  <p className="input-desc">Image description</p>
                  <input type="text" value={ val.metadata.imgDesc } onChange={ (e) => { val.metadata.imgDesc = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <p className="input-desc">Timer będzie odliczał do startu do godz 17:00 w piątek, do zakończenia do poniedziałku 8:00, cyklicznie</p>
                </div>
            } else if (value.type === "form") {
              let val = value

              if(!val.metadata) {
                val.metadata = {
                  type: "basic"
                }
              }

              component = <div>
                  <h4>Form type</h4>
                  <select onChange={ (e) => { val.metadata.type = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } } value={ val.metadata.type }>
                      <option value="basic">Basic</option>
                      <option value="extended">Extended</option>
                  </select>
                  <h4>Content (HTML)</h4>
                  <CKEditor
                      editor={ ClassicEditor }
                      config={ {
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                        heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                      } }
                      data={ val.custom }
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        //console.log( { event, editor, data } );
                        this.updateItem(props.key, "custom", data)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                  />
                </div>
            } else if (value.type === "letnie-brzmienia") {
              let val = value

              if(!val.custom) {
                val.custom = "available-soon"
              }

              component = <div>
                  <h4>Dostępność</h4>
                  <select onChange={ (e) => { this.updateItem(props.key, "custom", e.target.value) } } value={ val.custom }>
                      <option value="available-soon">Dostępne wkrótce</option>
                      <option value="available">Dostępne</option>
                      <option value="unavailable">Niedostępne</option>
                  </select>
                </div>
            } else if (value.type === "vignette") {
              let val = value

              if(!val.custom) {
                val.custom = "vignette"
              }

              component = <div>
                  <h4>Leave this module to show vignette on slide</h4>
                </div>
            } else if (value.type === "partner_info") {
              let val = value

              if (!val.file) {
                val.file = {uuid: ""}
              }

              if(!val.metadata) {
                val.metadata = {
                  alt: ""
                }
              }

              component = <div>
                  <h4>Content (HTML)</h4>
                  <CKEditor
                      editor={ ClassicEditor }
                      config={ {
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                        heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                      } }
                      data={ val.custom }
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        //console.log( { event, editor, data } );
                        this.updateItem(props.key, "custom", data)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                  />
                  <h4>Logo</h4>
                  <SingleImageUpload ident={ "image" } API={ this.props.API } image={ val.file } onChange={ (img) => {
                    val.file = img
                    this.updateItem(props.key, "file", val.file)
                    } }/>
                  <p className="input-desc">Alternative text</p>
                  <input type="text" value={ val.metadata.alt } onChange={ (e) => { val.metadata.alt = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                </div>
            } else if (value.type === "partner_info_text") {
              let val = value

              if(!val.metadata) {
                val.metadata = {
                  alt: ""
                }
              }

              component = <div>
                  <h4>Content (HTML)</h4>
                  <CKEditor
                      editor={ ClassicEditor }
                      config={ {
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                        heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                      } }
                      data={ val.custom }
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        //console.log( { event, editor, data } );
                        this.updateItem(props.key, "custom", data)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                  />
                </div>
            } else if (value.type === "event-additional") {
              let val = value

              if(!val.metadata) {
                val.metadata = {
                  alt: ""
                }
              }

              component = <div>
                  <h4>Content (HTML)</h4>
                  <CKEditor
                      editor={ ClassicEditor }
                      config={ {
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                        heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                      } }
                      data={ val.custom }
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        //console.log( { event, editor, data } );
                        this.updateItem(props.key, "custom", data)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                  />
                </div>
            } else if (value.type === "address") {
              let val = value

              if(!val.metadata) {
                val.metadata = {
                  name: "",
                  street: "",
                  buildingNumber: "",
                  apartmentNumber: "",
                  city: "",
                  postalCode: "",
                  latitude: "",
                  longitude: ""
                }
              }

              component = <div>
                  <h4>Content</h4>
                  <p className="input-desc">Nazwa</p>
                  <input type="text" value={ val.metadata.name } onChange={ (e) => { val.metadata.name = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <div className="flex">
                    <div>
                      <p className="input-desc">Ulica</p>
                      <input type="text" value={ val.metadata.street } onChange={ (e) => { val.metadata.street = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                    </div>
                    <div>
                      <p className="input-desc">Numer budynku</p>
                      <input type="text" value={ val.metadata.buildingNumber } onChange={ (e) => { val.metadata.buildingNumber = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                    </div>
                    <div>
                      <p className="input-desc">Numer lokalu</p>
                      <input type="text" value={ val.metadata.apartmentNumber } onChange={ (e) => { val.metadata.apartmentNumber = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                    </div>
                  </div>
                  <div className="flex">
                    <div>
                      <p className="input-desc">Miasto</p>
                      <input type="text" value={ val.metadata.city } onChange={ (e) => { val.metadata.city = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                    </div>
                    <div>
                      <p className="input-desc">Kod pocztowy</p>
                      <input type="text" value={ val.metadata.postalCode } onChange={ (e) => { val.metadata.postalCode = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                    </div>
                  </div>
                  <div className="flex">
                    <div>
                      <p className="input-desc">Szerokość geograficzna</p>
                      <input type="text" value={ val.metadata.latitude || 0 } onChange={ (e) => { val.metadata.latitude = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                    </div>
                    <div>
                      <p className="input-desc">Długość geograficzna</p>
                      <input type="text" value={ val.metadata.longitude || 0 } onChange={ (e) => { val.metadata.longitude = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                    </div>
                  </div>
                </div>
            } else if (value.type === "video") {
              let val = value

              if (!val.file) {
                val.file = {uuid: ""}
              }

              if(!val.metadata) {
                val.metadata = {
                  desc: "",
                  name: "",
                  altSrc: "",
                  videoLength: 15,
                  poster: {}
                }
              }

              component = <div>
                  <h4>File</h4>
                  <p>File type: MP4, maximum size: 7MB</p>
                  <SingleFileUpload ident={ "video" } API={ this.props.API } file={ val.metadata.name } onChange={ (file) => {
                    val.file.uuid = file.uuid
                    val.metadata.name = file.name
                    this.updateItem(props.key, "file", val.file)
                    } }/>
                  <p className="input-desc">Video title</p>
                  <input type="text" value={ val.metadata.desc } onChange={ (e) => { val.metadata.desc = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <p className="input-desc">Video length (in seconds)</p>
                  <input type="number" value={ val.metadata.videoLength } onChange={ (e) => { val.metadata.videoLength = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <p className="input-desc">Poster</p>
                  <SingleImageUpload ident={ "poster" } API={ this.props.API } file={ val.metadata.poster } onChange={ (file) => {
                    val.metadata.poster = file
                    this.updateItem(props.key, "metadata", val.metadata)
                    } }/>
                </div>
            } else if (value.type === "video-src") {
              let val = value

              if(!val.metadata) {
                val.metadata = {
                  desc: "",
                  altSrc: "",
                  highlighted: false
                }
              }

              component = <div>
                  <h4>Video</h4>
                  <p className="input-desc">Video title</p>
                  <input type="text" value={ val.metadata.desc } onChange={ (e) => { val.metadata.desc = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <p className="input-desc">Video URL</p>
                  <input type="text" value={ val.metadata.altSrc } onChange={ (e) => { val.metadata.altSrc = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <div className='checkbox'>
                    <input id={'input-highlighted-'+props.key} type="checkbox" value={ val.metadata.highlighted } checked={ val.metadata.highlighted } onChange={ (e) => { val.metadata.highlighted = !val.metadata.highlighted; this.updateItem(props.key, "metadata", val.metadata); } }/>
                    <label htmlFor={'input-highlighted-'+props.key}>Highlighted</label>
                  </div>
                </div>
            } else if (value.type === "video-yt") {
              let val = value

              if(!val.metadata) {
                val.metadata = {
                  desc: "",
                  altSrc: "",
                  highlighted: false
                }
              }

              component = <div>
                  <h4>Video</h4>
                  <p className="input-desc">Video title</p>
                  <input type="text" value={ val.metadata.desc } onChange={ (e) => { val.metadata.desc = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <p className="input-desc">YouTube video URL</p>
                  <input type="text" value={ val.metadata.altSrc } onChange={ (e) => { val.metadata.altSrc = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <div className='checkbox'>
                    <input id={'input-highlighted-'+props.key} type="checkbox" value={ val.metadata.highlighted } checked={ val.metadata.highlighted } onChange={ (e) => { val.metadata.highlighted = !val.metadata.highlighted; this.updateItem(props.key, "metadata", val.metadata); } }/>
                    <label htmlFor={'input-highlighted-'+props.key}>Highlighted</label>
                  </div>
                </div>
            } else if (value.type === "how_to_use") {
              let val = value

              if (!val.file) {
                val.file = {uuid: ""}
              }

              if(!val.metadata) {
                val.metadata = {
                  name: "",
                  desc: ""
                }
              }

              component = <div>
                  <h4>File</h4>
                  <p>File type: pdf</p>
                  <AddTerm restricted={false} API={ this.props.API } file={ val.metadata.name } onChange={ (file, fileName) => {
                    val.file = file
                    val.metadata.name = fileName
                    this.updateItem(props.key, "file", val.file)
                    } }/>
                  <p className="input-desc">View name</p>
                  <input type="text" value={ val.metadata.desc } onChange={ (e) => { val.metadata.desc = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                </div>
            } else if (value.type === "bullets" || value.type === "how_to_use_bullets") {
              let val = value

              // if (!val.file) {
              //   val.file = {uuid: ""}
              // }

              if(!val.metadata) {
                val.metadata = {
                  name: "",
                  desc: ""
                }
              }

              // component = <div>
              //     <h4>File</h4>
              //     <p>File type: pdf</p>
              //     <AddTerm restricted={false} API={ this.props.API } file={ val.metadata.name } onChange={ (file, fileName) => {
              //       val.file = file
              //       val.metadata.name = fileName
              //       this.updateItem(props.key, "file", val.file)
              //       } }/>
              //     <p className="input-desc">View name</p>
              //     <input type="text" value={ val.metadata.desc } onChange={ (e) => { val.metadata.desc = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
              //   </div>
                  component = <div>
                  <h4>Content (only bulleted list)</h4>
                  <CKEditor
                      editor={ ClassicEditor }
                      config={ {
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                        heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                      } }
                      data={ val.custom }
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        //console.log( { event, editor, data } );
                        this.updateItem(props.key, "custom", data)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                  />
                </div>
            } else if (value.type === "terms_pdf") {
              let val = value

              if (!val.file) {
                val.file = {uuid: ""}
              }

              if(!val.metadata) {
                val.metadata = {
                  name: "",
                  desc: ""
                }
              }

              component = <div>
                  <h4>File</h4>
                  <p>File type: pdf</p>
                  <AddTerm restricted={false} API={ this.props.API } file={ val.metadata.name } onChange={ (file, fileName) => {
                    val.file = file
                    val.metadata.name = fileName
                    this.updateItem(props.key, "file", val.file)
                    } }/>
                  <p className="input-desc">View name</p>
                  <input type="text" value={ val.metadata.desc } onChange={ (e) => { val.metadata.desc = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                </div>
            } else if (value.type === "terms_file") {
              let val = value

              if (!val.file) {
                val.file = {uuid: ""}
              }

              if(!val.metadata) {
                val.metadata = {
                  name: "",
                  desc: ""
                }
              }

              component = <div>
                  <h4>File</h4>
                  <p>File type: pdf</p>
                  <AddTerm restricted={false} API={ this.props.API } file={ val.metadata.name } onChange={ (file, fileName) => {
                    val.file = file
                    val.metadata.name = fileName
                    this.updateItem(props.key, "file", val.file)
                    } }/>
                  <p className="input-desc">View name</p>
                  <input type="text" value={ val.metadata.desc } onChange={ (e) => { val.metadata.desc = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                </div>
            } else if (value.type === "article-file") {
              let val = value

              if (!val.file) {
                val.file = {uuid: ""}
              }

              if(!val.metadata) {
                val.metadata = {
                  desc: "",
                  name: ""
                }
              }

              component = <div>
                  <h4>File</h4>
                  <SingleFileUpload ident={ "article" } API={ this.props.API } file={ val.metadata.name } onChange={ (file) => {
                    val.file.uuid = file.uuid
                    val.metadata.name = file.name
                    console.log(val)
                    this.updateItem(props.key, "file", val.file)
                    } }/>
                  <p className="input-desc">File description</p>
                  <input type="text" value={ val.metadata.desc } onChange={ (e) => { val.metadata.desc = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                </div>
            } else if (value.type === "offer-file") {
              let val = value

              if (!val.file) {
                val.file = {uuid: ""}
              }

              if(!val.metadata) {
                val.metadata = {
                  desc: "",
                  name: ""
                }
              }

              component = <div>
                  <h4>File</h4>
                  <SingleFileUpload ident={ "offer" } API={ this.props.API } file={ val.metadata.name } onChange={ (file) => {
                    val.file.uuid = file.uuid
                    val.metadata.name = file.name
                    console.log(val)
                    this.updateItem(props.key, "file", val.file)
                    } }/>
                  <p className="input-desc">File description</p>
                  <input type="text" value={ val.metadata.desc } onChange={ (e) => { val.metadata.desc = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                </div>
            } else if (value.type === "thumbnail-mobile" || value.type === "thumbnail-desktop") {
              let val = value

              if (!val.file) {
                val.file = {uuid: ""}
              }

              if(!val.metadata) {
                val.metadata = {
                  alt: "",
                  imgDesc: ""
                }
              }

              component = <div>
                  <h4>Image</h4>
                  <SingleImageUpload ident={ "thumbnail" } API={ this.props.API } image={ val.file.uuid } onChange={ (img) => {
                    val.file.uuid = img
                    this.updateItem(props.key, "file", val.file)
                    } }/>
                  <p className="input-desc">Alternative text</p>
                  <input type="text" value={ val.metadata.alt } onChange={ (e) => { val.metadata.alt = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <p className="input-desc">Image description</p>
                  <input type="text" value={ val.metadata.imgDesc } onChange={ (e) => { val.metadata.imgDesc = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                </div>
            } else if (value.type === "featured") {
              let val = value

              if (!val.file) {
                val.file = {uuid: ""}
              }

              if(!val.metadata) {
                val.metadata = {
                  imgDesc: "",
                  mobileImg: {uuid: ""}
                }
              }

              component = <div>
                  <h4>Images</h4>
                  <p className="input-desc">Image description</p>
                  <input type="text" value={ val.metadata.imgDesc } onChange={ (e) => { val.metadata.imgDesc = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <h4>Featured image</h4>
                  <SingleImageUpload key={val.file.uuid} ident={ "thumbnail" } API={ this.props.API } image={ val.file } onChange={ (img) => {
                    val.file = img
                    this.updateItem(props.key, "file", val.file)
                    } }/>
                    <h4>Mobile image</h4>
                  <SingleImageUpload key={val.metadata.mobileImg?.uuid} ident={ "thumbnail" } API={ this.props.API } image={ val.metadata.mobileImg } onChange={ (img) => {
                    val.metadata.mobileImg = img
                    this.updateItem(props.key, "metadata", val.metadata)
                    } }/>
                </div>
            } else if (value.type === "turbo_additional") {
              let val = value

              if (!val.file) {
                val.file = {uuid: ""}
              }

              if(!val.metadata) {
                val.metadata = {
                  title: "",
                  description: ""
                }
              }

              component = <div>
                  <h4>Turbo coupon additional info</h4>
                  <p className="input-desc">Title</p>
                  <input type="text" value={ val.metadata.title } onChange={ (e) => { val.metadata.title = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <p className="input-desc">Description</p>
                  <input type="text" value={ val.metadata.description } onChange={ (e) => { val.metadata.description = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <h4>Image</h4>
                  <SingleImageUpload key={val.file.uuid} ident={ "thumbnail" } API={ this.props.API } image={ val.file } onChange={ (img) => {
                    val.file = img
                    this.updateItem(props.key, "file", val.file)
                    } }/>
                </div>
            } else if (value.type === "button") {
              let val = value

              if(!val.metadata) {
                val.metadata = {
                  name: ""
                }
              }

              component = <div>
                  <h4>URL address</h4>
                  <p className="input-desc">Will be external URL if consists http/https on the beginning</p>
                  <input type="text" value={ value.custom } onChange={ (e) => { this.updateItem(props.key, "custom", e.target.value) } }/>
                  <h4>Button title</h4>
                  <input type="text" value={ val.metadata.name } onChange={ (e) => { val.metadata.name = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                </div>
            } else if (value.type === "activity_button") {
              let val = value

              if(!val.metadata) {
                val.metadata = {
                  titleStart: "",
                  urlStart: "",
                  titleCompleted: "",
                  urlCompleted: ""
                }
              }

              component = <div>
                  <h4>Activity start button title</h4>
                  <p className="input-desc">Visible when activity is NOT completed</p>
                  <input type="text" value={ val.metadata.titleStart } onChange={ (e) => { val.metadata.titleStart = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <h4>Activity completed button title</h4>
                  <p className="input-desc">Visible when activity is completed</p>
                  <input type="text" value={ val.metadata.titleCompleted } onChange={ (e) => { val.metadata.titleCompleted = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <h4>Start button custom URL (optional)</h4>
                  <p className="input-desc">Custom URL when activity is NOT completed (when empty redirects to activity)</p>
                  <input type="text" value={ val.metadata.urlStart } onChange={ (e) => { val.metadata.urlStart = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <h4>Completed button custom URL (optional)</h4>
                  <p className="input-desc">Custom URL when activity is completed (when empty redirects to all activities list)</p>
                  <input type="text" value={ val.metadata.urlCompleted } onChange={ (e) => { val.metadata.urlCompleted = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                </div>
            } else if (value.type === "text-cta") {
              let val = value

              if(!val.metadata) {
                val.metadata = {
                  highlighted: false,
                  url_1: "",
                  name_1: "",
                  color_1: "orange",
                  url_2: "",
                  name_2: "",
                  color_2: "orange",
                  url_3: "",
                  name_3: "",
                  color_3: "orange"
                }
              }

              component = <div>
                <h4>Content (HTML)</h4>
                  <CKEditor
                      editor={ ClassicEditor }
                      config={ {
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                        heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                      } }
                      data={ val.custom }
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        //console.log( { event, editor, data } );
                        this.updateItem(props.key, "custom", data)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                  />
                  <h4>Button 1</h4>
                  <p className="input-desc">Text</p>
                  <input type="text" value={ value.metadata.url_1 } onChange={ (e) => { val.metadata.url_1 = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <p className='input-desc'>URL</p>
                  <input type="text" value={ val.metadata.name_1 } onChange={ (e) => { val.metadata.name_1 = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <p className='input-desc'>Kolor</p>
                  <select onChange={ (e) => { val.metadata.color_1 = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } } value={ val.metadata.color_1 }>
                      <option value="orange">Pomarańczowy</option>
                      <option value="white">Biały</option>
                  </select>
                  <h4>Button 2</h4>
                  <p className="input-desc">Text</p>
                  <input type="text" value={ value.metadata.url_2 } onChange={ (e) => { val.metadata.url_2 = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <p className='input-desc'>URL</p>
                  <input type="text" value={ val.metadata.name_2 } onChange={ (e) => { val.metadata.name_2 = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <p className='input-desc'>Kolor</p>
                  <select onChange={ (e) => { val.metadata.color_2 = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } } value={ val.metadata.color_2 }>
                      <option value="orange">Pomarańczowy</option>
                      <option value="white">Biały</option>
                  </select>
                  <h4>Button 3</h4>
                  <p className="input-desc">Text</p>
                  <input type="text" value={ value.metadata.url_3 } onChange={ (e) => { val.metadata.url_3 = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <p className='input-desc'>URL</p>
                  <input type="text" value={ val.metadata.name_3 } onChange={ (e) => { val.metadata.name_3 = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <p className='input-desc'>Kolor</p>
                  <select onChange={ (e) => { val.metadata.color_3 = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } } value={ val.metadata.color_3 }>
                      <option value="orange">Pomarańczowy</option>
                      <option value="white">Biały</option>
                  </select>
                  <div className='checkbox'>
                    <input id={'input-highlighted-'+props.key} type="checkbox" value={ val.metadata.highlighted } checked={ val.metadata.highlighted } onChange={ (e) => { val.metadata.highlighted = !val.metadata.highlighted; this.updateItem(props.key, "metadata", val.metadata); } }/>
                    <label htmlFor={'input-highlighted-'+props.key}>Highlighted</label>
                  </div>
                </div>
            } else if (value.type === "external_url") {
              component = <div>
                  <h4>URL address</h4>
                  <p className="input-desc">Will be external URL if consists http/https on the beginning</p>
                  <input type="text" value={ value.custom } onChange={ (e) => { this.updateItem(props.key, "custom", e.target.value) } }/>
                </div>
            }  else if (value.type === "map_point") {
              component = <div>
                  <h4>URL address</h4>
                  <p className="input-desc">URL with props for map</p>
                  <input type="text" value={ value.custom } onChange={ (e) => { this.updateItem(props.key, "custom", e.target.value) } }/>
                </div>
            }  else if (value.type === "custom_statute_url") {
              component = <div>
                  <h4>URL address</h4>
                  <p className="input-desc">URL for specific terms</p>
                  <input type="text" value={ value.custom } onChange={ (e) => { this.updateItem(props.key, "custom", e.target.value) } }/>
                </div>
            } else if (value.type === "terms_url") {
              component = <div>
                  <h4>URL address</h4>
                  <p className="input-desc">URL for specific terms</p>
                  <input type="text" value={ value.custom } onChange={ (e) => { this.updateItem(props.key, "custom", e.target.value) } }/>
                </div>
            } else if (value.type === "category") {
              let val = value
              if (!val.custom) {
                val.custom = ""
              }
              component = <div>
                  <h4>Category</h4>
                  <p className="input-desc">Will be notification category</p>
                  <input type="text" value={ val.custom } onChange={ (e) => { this.updateItem(props.key, "custom", e.target.value) } }/>
                </div>
            } else if (value.type === "url") {
              let val = value
              if (!val.custom) {
                val.custom = ""
              }
              component = <div>
                  <h4>URL address</h4>
                  <p className="input-desc">Will be external URL if consists http/https on the beginning</p>
                  <input type="text" value={ val.custom } onChange={ (e) => { this.updateItem(props.key, "custom", e.target.value) } }/>
                </div>
            } else if (value.type === "image") {
              let val = value

              if (!val.file) {
                val.file = {uuid: ""}
              }

              if (!val.custom) {
                val.custom = ""
              }

              if(!val.metadata) {
                val.metadata = {
                  alt: "",
                  imgDesc: "",
                  highlighted: false,
                  // full: false,
                  // sideColor: "#ffffff"
                }
              }

              component = <div>
                  <h4>Image</h4>
                  <SingleImageUpload ident={ "image" } API={ this.props.API } image={ val.file } onChange={ (img) => {
                    val.file = img
                    this.updateItem(props.key, "file", val.file)
                    } }/>
                  <p className="input-desc">Image description</p>
                  <input type="text" value={ val.metadata.imgDesc } onChange={ (e) => { val.metadata.imgDesc = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <div className='checkbox'>
                    <input id={'input-highlighted-'+props.key} type="checkbox" value={ val.metadata.highlighted } checked={ val.metadata.highlighted } onChange={ (e) => { val.metadata.highlighted = !val.metadata.highlighted; this.updateItem(props.key, "metadata", val.metadata); } }/>
                    <label htmlFor={'input-highlighted-'+props.key}>Highlighted</label>
                  </div>
                  <div className='checkbox'>
                    <input id={'image-full-width-'+props.key} type="checkbox" checked={ val.metadata.fullWidth || false } onChange={ (e) => { val.metadata.fullWidth = !val.metadata.fullWidth; this.updateItem(props.key, "metadata", val.metadata); } }/>
                    <label htmlFor={'image-full-width-'+props.key}>Full width</label>
                  </div>
                  {/* <div className='checkbox'>
                    <input id={'image-full-border'} type="checkbox" value={ val.metadata.full || false } checked={ val.metadata.full } onChange={ (e) => { val.metadata.full = !val.metadata.full; this.updateItem(props.key, "metadata", val.metadata); } }/>
                    <label htmlFor={'image-full-border'}>Full width</label>
                  </div>
                  {val.metadata.border  && <div className='color'>
                    <label htmlFor={'image-side-borders'}>Side color</label>
                    <input id={'image-side-borders'} type="color" value={ val.metadata.sideColor || "#ffffff"} onChange={ (e) => { val.metadata.sideColor = e.target.value; this.updateItem(props.key, "metadata", val.metadata); } }/>
                  </div>} */}
                </div>
            } else if (value.type === "gallery") {
              let val = value

              if (!val.custom) {
                val.custom = ""
              }

              if(!val.metadata) {
                val.metadata = {
                  images: [{
                    url: ""
                  }],
                  layout: "right"
                }
              }

              component = <div>
                  <h4>Image layout</h4>
                  <select onChange={ (e) => { val.metadata.layout = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } } value={ val.metadata.layout }>
                      <option value="left">First from left</option>
                      <option value="right">First from right</option>
                  </select>
                  <h4>Gallery</h4>
                  <ul className='gallery-list' style={{ padding: 0, listStyle: "none" }}>
                    { val.metadata?.images?.map((img, i) => {
                      return (
                        <li key={i} style={{ marginBottom: "12px", position: "relative" }}>
                          <SingleImageUpload ident={ "image" } API={ this.props.API } image={ val.metadata.images[i] } onChange={ (img) => {
                          val.metadata.images[i].url = img.url
                          this.updateItem(props.key, "metadata", val.metadata)
                          } }/>
                          <span className='remove-file' onClick={() => { val.metadata.images.splice(i, 1); this.updateItem(props.key, "metadata", val.metadata) }}>Delete</span>
                        </li>
                      )
                    }) }
                  </ul>
                  <button onClick={(e) => { e.preventDefault(); val.metadata.images.push({url:""}); this.updateItem(props.key, "metadata", val.metadata) }}>Add image</button>
                  <p className="input-desc">Title (optional)</p>
                  <input type="text" value={ val.custom } onChange={ (e) => { this.updateItem(props.key, "custom", e.target.value) } }/>
                </div>
            } else if (value.type === "logo-list") {
              let val = value

              if (!val.custom) {
                val.custom = ""
              }

              if(!val.metadata) {
                val.metadata = {
                  images: [{
                    url: ""
                  }]
                }
              }

              component = <div>
                  <h4>Logo - list</h4>
                  <ul className='gallery-list logo' style={{ padding: 0, listStyle: "none" }}>
                    { val.metadata?.images?.map((img, i) => {
                      return (
                        <li key={i} style={{ marginBottom: "12px", position: "relative" }}>
                          <SingleImageUpload ident={ "image" } API={ this.props.API } image={ val.metadata.images[i] } onChange={ (img) => {
                          val.metadata.images[i].url = img.url
                          this.updateItem(props.key, "metadata", val.metadata)
                          } }/>
                          <span className='remove-file' onClick={() => { val.metadata.images.splice(i, 1); this.updateItem(props.key, "metadata", val.metadata) }}>Delete</span>
                        </li>
                      )
                    }) }
                  </ul>
                  <button onClick={(e) => { e.preventDefault(); val.metadata.images.push({url:""}); this.updateItem(props.key, "metadata", val.metadata) }}>Add logo</button>
                  <p className="input-desc">Section title (optional)</p>
                  <input type="text" value={ val.custom } onChange={ (e) => { this.updateItem(props.key, "custom", e.target.value) } }/>
                </div>
            } else if (value.type === "online_redeem") {
              let val = value

              if (!val.custom) {
                val.custom = ""
              }

              if(!val.metadata) {
                val.metadata = {
                  about: "",
                  copy: "",
                  howToUse: "",
                  onlineStore: "",
                  logos: [{
                    file: {
                      url: ""
                    }
                  }]
                }
              }

              component = <div>
                  <p className="input-desc">Section title</p>
                  <input type="text" value={ val.metadata.copy } onChange={ (e) => { val.metadata.copy = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <p className="input-desc">Section description</p>
                  <CKEditor
                      editor={ ClassicEditor }
                      config={ {
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                        heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                      } }
                      data={ val.metadata.about }
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        //console.log( { event, editor, data } );
                        val.metadata.about = data
                        this.updateItem(props.key, "metadata", val.metadata)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                  />
                  <h4>Logos</h4>
                  <ul className='gallery-list logo' style={{ padding: 0, listStyle: "none" }}>
                    { val.metadata?.logos?.map((img, i) => {
                      return (
                        <li key={i} style={{ marginBottom: "12px", position: "relative", backgroundColor: "#F4F4F4" }}>
                          <SingleImageUpload hideDel={true} ident={ "image" } API={ this.props.API } image={ val.metadata.logos[i].file } onChange={ (img) => {
                          val.metadata.logos[i].file = img
                          this.updateItem(props.key, "metadata", val.metadata)
                          } }/>
                          <span className='remove-file' onClick={() => { val.metadata.logos.splice(i, 1); this.updateItem(props.key, "metadata", val.metadata) }}>Delete</span>
                        </li>
                      )
                    }) }
                  </ul>
                  <button onClick={(e) => { e.preventDefault(); val.metadata.logos.push({file:{url:""}}); this.updateItem(props.key, "metadata", val.metadata) }}>Add logo</button>
                  <h4>How to use bullets</h4>
                  <CKEditor
                      editor={ ClassicEditor }
                      config={ {
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                        heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                      } }
                      data={ val.metadata.howToUse }
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        //console.log( { event, editor, data } );
                        val.metadata.howToUse = data
                        this.updateItem(props.key, "metadata", val.metadata)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                  />
                  <div>
                    <h4>Select store</h4>
                    <select value={ val.metadata.onlineStore } onChange={ (e) => { val.metadata.onlineStore = e.target.value; this.updateItem(props.key, "metadata", val.metadata); } }>
                      <option value="">None</option>
                      <option value="discoverglo">discoverglo.com</option>
                      <option value="velo">velo.com</option>
                    </select>
                  </div>
                </div>
            } else if (value.type === "point_redeem" || value.type === "partner_redeem") {
              let val = value

              if (!val.custom) {
                val.custom = ""
              }

              if(!val.metadata) {
                val.metadata = {
                  about: "",
                  copy: "",
                  howToUse: "",
                  mapFilters: [],
                  logos: [{
                    file: {
                      url: ""
                    }
                  }]
                }
              }

              component = <div>
                  <p className="input-desc">Section title</p>
                  <input type="text" value={ val.metadata.copy } onChange={ (e) => { val.metadata.copy = e.target.value; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  <p className="input-desc">Section description</p>
                  <CKEditor
                      editor={ ClassicEditor }
                      config={ {
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                        heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                      } }
                      data={ val.metadata.about }
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        //console.log( { event, editor, data } );
                        val.metadata.about = data
                        this.updateItem(props.key, "metadata", val.metadata)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                  />
                  <h4>Logos</h4>
                  <ul className='gallery-list logo' style={{ padding: 0, listStyle: "none" }}>
                    { val.metadata?.logos?.map((img, i) => {
                      return (
                        <li key={i} style={{ marginBottom: "12px", position: "relative", backgroundColor: "#F4F4F4" }}>
                          <SingleImageUpload hideDel={true} ident={ "image" } API={ this.props.API } image={ val.metadata.logos[i].file } onChange={ (img) => {
                          val.metadata.logos[i].file = img
                          this.updateItem(props.key, "metadata", val.metadata)
                          } }/>
                          <span className='remove-file' onClick={() => { val.metadata.logos.splice(i, 1); this.updateItem(props.key, "metadata", val.metadata) }}>Delete</span>
                        </li>
                      )
                    }) }
                  </ul>
                  <button onClick={(e) => { e.preventDefault(); val.metadata.logos.push({file:{url:""}}); this.updateItem(props.key, "metadata", val.metadata) }}>Add logo</button>
                  <h4>How to use bullets</h4>
                  <CKEditor
                      editor={ ClassicEditor }
                      config={ {
                        toolbar: [ 'heading', '|', 'bold', 'italic', 'alignment', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'undo', 'redo' ],
                        heading: {
                          options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'H1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h3', title: 'H2', class: 'ck-heading_heading2' }
                          ]
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          decorators: {
                            addTargetToExternalLinks: {
                              mode: 'automatic',
                              callback: url => /^(https?:)?\/\//.test( url ),
                              attributes: {
                                  target: '_blank',
                                  rel: 'noopener noreferrer'
                                }
                            }
                          }
                        }
                      } }
                      data={ val.metadata.howToUse }
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        //console.log( { event, editor, data } );
                        val.metadata.howToUse = data
                        this.updateItem(props.key, "metadata", val.metadata)
                      } }
                      onBlur={ ( event, editor ) => {
                          //console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          //console.log( 'Focus.', editor );
                      } }
                  />
                  <h4>Map filters</h4>
                  <div>
                    <MultiselectComponent API={this.props.API} table={"filters"} endpoint={"/map/filter/list"} selected={val.metadata.mapFilters || []} select={ (e) => { val.metadata.mapFilters = e; this.updateItem(props.key, "metadata", val.metadata) } }/>
                  </div>
                </div>
            }
            
            return <li {...props} style={{
              ...props.style,
              padding: '1.5em',
              margin: '0.5em 0em',
              listStyleType: 'none',
              cursor: isDragged ? 'grabbing' : 'grab',
              border: '2px solid #CCC',
              color: '#333',
              borderRadius: '5px',
              fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
              backgroundColor: isDragged || isSelected ? '#EEE' : '#FFF',
              display: value.trash || value.singleUse ? "none" : "block",
              zIndex: 100-props.key
            }} onKeyDown={ null } data-moduletype={value.type} data-moduleindex={props.key}>
              <h4>Type of module</h4>
              <p className="input-desc">Select type of module</p>
              <select value={ value.type } onChange={ (e) => { this.clearItem(props.key, e.target.value) } }>
                <option value="">Select type of module</option>
                { this.props.availableModules.map((module, j) => {
                  return <option value={module} key={ j }>{ module }</option>
                }) }
                {/* <option value="intro">intro</option>
                <option value="text">text</option>
                <option value="columns">columns</option>
                <option value="image-text">image-text</option>
                <option value="disclaimer">disclaimer</option>
                <option value="agenda">agenda</option>
                <option value="thumbnail-mobile">thumbnail-mobile</option>
                <option value="thumbnail-desktop">thumbnail-desktop</option>
                <option value="article-file">article-file</option>
                <option value="button">button</option> */}
              </select>
              <h4>Module content</h4>
              <p className="input-desc">Enter module content</p>
              { component }
              <button
                onClick={(e) => {
                  e.preventDefault()
                  this.removeItem(props.key)
                }}
                style={buttonStyles}
              >
                <RemovableIcon />
              </button>
              <button
                data-movable-handle
                style={{
                  ...buttonStyles,
                  cursor: isDragged ? 'grabbing' : 'grab',
                  marginRight: '3em'
                }}
                tabIndex={-1}
              >
                <HandleIcon />
              </button>
            </li> }
          }
        />
        <button className="btn" onClick={ (e) => { e.preventDefault(); this.props.addModule() } }><span>Add module</span></button>
      </div>
    )
  }
}
